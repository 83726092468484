<template>
<div class="SuccessCases-box">
  <div class="banner-box">
    <img src="@/assets/page-banner-join.png" alt="">
  </div>
</div>
  <div class="content-bg">
    <div class="msg-80">
      <div class="casesTitle">
        <span class="cases-cn">成功案例</span>
        <span class="cases-en">Success Cases</span>
      </div>
      <div class="card-box-all">
        <div v-for="(items,index) in casesItems" :key="index" class="card-box">
          <img :src="require('@/assets/' + items.imagePath)"  alt="">
          <div class="content-box">
            <p class="title">{{items.title}}</p>
            <p class="text">{{items.text}}</p>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "SuccessCases",
  data(){
    return{
      casesItems:[
        {
          imagePath:'cases-card-one.png',
          title:'博雷顿',
          text:'博雷顿聚焦于新能源战略主航道，具备正向整车研发、技术整合、生产制造等全产业链整合能力，以自主研发的动力总成、整车电子电气架构、电池pack及温度管理系统、智能化等核心技术为基础，打造纯电动工程机械、矿用自卸车、公路重卡、无人驾驶等创新产品，已发展成为新能源工程机械和重卡领域的重要力量。'
        },
        {
          imagePath:'cases-card-two.png',
          title:'明堂红木',
          text:'我们用纯AI生成内容为明堂做的导流小红书账号，帮助名堂红木从红木产品-产品+服务的企业转型、目前处于调研阶段。'
        },
        {
          imagePath:'cases-card-three.png',
          title:'名皇珍珠',
          text:'我们用AI生成内容做营销海报、降低营销成本快速出图，帮助名皇Tiktok营销落地、产品出海、流程管理和内部提效。'
        }
      ]
    }
  }
}
</script>

<style scoped lang="less">
.SuccessCases-box{
  width:100%;
  float: left;
  .banner-box{
    width:100%;
    display: flex;
    justify-content: center;
    img{
      width:100%;
    }
  }
}
.content-bg{
  width:100%;
  min-height:70vh;
  background: #ffffff;
  float: left;
}
.msg-80{
  width:70%;
  margin: 0 auto;
  .casesTitle{
    color:#485873;
    font-family: 'textFontW7' , serif;
    font-style: italic;
    padding-top:20px;
    .cases-cn{
      padding-left:2vw;
      font-size: 1.8vw;
    }
    .cases-en{
      padding-left: 20px;
      font-size: 1.5vw;
    }
  }
}
.card-box-all{
  width:100%;
  .card-box{
    width:calc(100% / 3 - 2vw);
    padding:1vw;
    float: left;
    cursor: pointer;
    img{
      display: flex;
      justify-content: center;
      max-width:20vw;
      border:0.6vw solid rgba(0, 0, 0, 0.1);
    }
    .content-box{
      width:100%;
      float: left;
      .title{
        background: #f1f1f1;
        padding:1vh 1vw;
        text-indent: 1em;
        color: #b06311;
        width:90%;
        font-size:1.5vw;
        font-family:'textFontW3',serif;
      }
      .text{
        background: rgba(0, 0, 0, 0.1);
        padding:1vh 1vw 3vh;
        width:90%;
        line-height: 3vh;
        height:20vh;
        text-indent: 2em;
        font-size: 0.9vw;
        border-radius:0 0 10px 10px;
      }
    }
  }
.card-box:hover{
  opacity: .6;
  .content-box{
    .title,.text{

    }
  }
}
}
@media screen and (max-width:2560px) and (min-width:1920px){
  .card-box-all {
    .card-box {
      img {
        width: 25vw;
      }
    }
  }
}
</style>