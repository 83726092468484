<template>
  <div class="us-all">
    <div class="banner-box">
      <img src="@/assets/companyprofile.png" alt="">
    </div>
    <div class="us-bg">
      <div class="us-box">
        <img src="@/assets/companytitle.png" alt="" class="left">
        <p>
          <i>艺数范式</i> 从2017年星杰云科技起步，经过三年实现星杰别墅装修业务的数字化，带动连续三年20%业务增长。后更名为“艺数范式”，专攻企业数字化解决方案和产业互联网新商业模式探索。
          <span><i>咨询：</i>拥有<strong>6位IBM BLM战略体系实践经验的咨询专家</strong>，提供泛家居行业的BLM体系专业战略规划咨询服务。</span>
          <span><i>产品：</i>擅长将企业数字化与消费互联网体验相结合，在泛家居行业完成业务模型建模，形成<strong>独创的1+3产品体系</strong>，覆盖从营销，成交到交付的全链路数字化。</span>
          <span><i>技术：</i>我们的技术团队从SAP、IBM、Oracle的实施与定制开发进化到阿里的中台和腾讯的小程序，<strong>专精于跨平台集成和低代码交付，降低企业数字化升级的技术成本。</strong></span>
          <span><i>运营：</i>我们积累了丰富的大平台如微信、小红书、抖音、Tiktok等运营资源，擅长<strong>线上线下互动，创新传统业务模式。</strong></span>
          <span><i>艺数范式</i>是您实现企业数字化转型的专业伙伴，我们旨在助力企业优化运营，实现持续增长。</span>
        </p>
        <img src="@/assets/companyphoto-woman.png" alt="" class="right">
      </div>
    </div>
  </div>
  <div class="advertisement-three">
    <img src="@/assets/advertisement06.png" alt="">
  </div>
</template>

<script>
export default {
  name: "AboutUs"
}
</script>

<style scoped lang="less">
.us-all{
  width:100%;
  float: left;
  .banner-box{
    width:100%;
    display: flex;
    justify-content: center;
    img{
      width:100%;
    }
  }
}
.us-bg{
  background: #ffffff;
  float: left;
  margin-top: -15vh;
  padding:15vh 0 0;
}
.us-box{
  width:80%;
  float: left;
  margin-left: 15vw;
  padding-top:4vh;
  font-size:1vw;
  line-height: 4vh;
  .left,.right{
    display: block;
    float: left;
  }
  .left{
    width:5vw;
  }
  .right{
    margin-top: 15vh;
    padding-left:20px;
    width:28vw;
  }
  p{
    //background: url("@/assets/companycontent-bg.png") no-repeat scroll 50% 50%;
    //background-size: 100% 90%;
    width:53%;
    display: block;
    text-indent: 2em;
    padding:1vw;
    float: left;
    font-family: 'Adobe 黑体 Std',serif;
    i{
      font-family: 'textFontFZ',serif;
      font-size: 2vw;
    }
    span{
      text-indent: 2em;
      display: block;
      width:100%;
      i{
        font-family: 'textFontFZ',serif;
        font-size: 1.2vw;
      }
      strong{
        color: #bb6d23;
        font-size: 1.2vw;
      }
    }
  }
}
.title-text{
  text-align: center;
  color:#485873;
  font-size: 2vw;
}
.advertisement-four{
  width:42%;
  float: left;
  margin-top: 3vh;
  img{
    display: block;
    margin: 2vh auto 0;
    width:90%;
  }
}
.advertisement-three{
  width:100%;
  .title-text{
    width:100%;
    text-align: center;
    float: left;
    margin-top:30px;
  }
  img{
    display: block;
    margin: 1vh auto 0;
    width:70%;
  }
}
</style>