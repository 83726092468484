<template>
  <div>
    <child-component @submit-success="showSuccessModal"></child-component>
<!--    <showWindow v-if="showModal" @close="showModal = false" ></showWindow>-->
  </div>
</template>

<script>
import childComponent from "@/components/child-component";
// import showWindow from "@/components/ShowWindow";

export default {
  name: "FromWindows",
  components: {
    childComponent,
    // showWindow
  },
  data() {
    return {
      showModal: false
    };
  },
  methods: {
    showSuccessModal() {
      // 根据需要处理传递过来的数据
      this.showModal = true;
    }
  },
  watch:{
    showModal(newValue){
      if (newValue) {
        setTimeout(() => {
          this.showModal = false;
        }, 1000);
      }
    }
  }
};
</script>

<style scoped lang="less">
.windows{
  width:100%;
  height:300px;
  line-height: 300px;
  position: relative;
  background: #000;
}
</style>