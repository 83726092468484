<template>
  <index-all></index-all>
</template>

<script>
import IndexAll from '@/components/IndexAll.vue'
export default {
  name: "HomePage",
  components:{
    IndexAll
  },
  data() {
    return {
      scaleFactor: 1
    };
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize(); // 初始化页面大小
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      // 获取浏览器可视空间的高度
      const viewportHeight = window.innerHeight;
      // 计算缩放比例，可以根据实际需求进行调整
      this.scaleFactor = viewportHeight / 100; // 假设设计稿高度为100px
    }

  }
}
</script>

<style lang="less">
@media screen and (max-width:450px){
  body{
    font-size: 12px!important;
  }
  .top-menu {
    .logo{
      height:3vh!important;
      margin: 0 !important;
    }
    .top{
      width:100%;
      height:1vh !important;
    }
  }
  .carousel{
    top:-5vh!important;
  }
  .stepsBox_all{
    margin-top:-15vh !important;
    .card-all{
      width:100%;
      clear: none !important;
      line-height: unset;
      img{
        width:5vw !important;
        height:3vh !important;
      }
      .text-box{
        .title{
          height:2vh;
        }
        .content{
          width:100%!important;
          line-height: 1vh!important;
          padding:0 !important;
          margin: 0 !important;
        }
      }
    }
  }
  .prev,.next{
    width:20px!important;
    height:20px!important;
    img{
      width:20px!important;
      height:20px!important;
    }
  }
  .slides .active {
    .item1 {
      .spanOne {
        top: 6vh!important;
        left: 32vw;
      }

      .spanTwo {
        top: 7vh!important;
        left: 6vw;
      }
    }
    .item2 {
      .spanOne {
        top: 7vh!important;
        left: 10vw;
      }

      .spanTwo {
        top: 8vh!important;
        left: -14vw;
      }
    }
    .item3 {
      .spanOne {
        top: 10vh !important;
        left: 20vw;
      }

      .spanTwo {
        top: 8vh !important;
        left: -8vw;
      }
    }
  }
  .message-box-all{
    margin-top: -5.5vh!important;
    .advertisement-title-box{
      height:20px!important;
      padding-top:0!important;
      .up,.bottom{
        height:10px!important;
        line-height: 10px!important;
      }
    }
    .advertisement-one{
      margin-top: 6%!important;
      .card-up,.card-down{
        .title-box{
          .subtitle-text{
            width:30px!important;
            line-height: 1.5vh !important;
          }
        }
        .text-box{
          span{
            line-height: 2vh!important;
          }
        }
      }
      .card-down{
        margin: 0% 20% 30px 0!important;
      }
      .decorative-strip-left-bottom{
        height:15vh!important;
        background-size:80% 30%!important;
        top:5vh !important;
      }
    }
    .decorative-strip-right-bottom{
      height:15vh!important;
      background-size:80% 30%!important;
      top:20vh !important;
    }
  }
  .footer-all{
    height:12vh!important;
    .footer-logo{
      height:3vh!important;
      img{
        margin-top: 1vh!important;
      }
    }
    .footer-menu{
      .menu-text{
        height:6vh!important;
        dd{
          line-height: 1vh!important;
        }
      }
    }
    .phone{
      height:5vh!important;
    }
    .end-lbl{
      margin: 0 auto!important;
      font-size: 0.8vw!important;
      .msg{
        width:150px!important;
        font-size: 0.8vw!important;
      }
    }
  }
  .us-box{
    line-height: 1vh!important;
    .right{
      margin-top:3vh !important;
      width:25vw!important;
    }
  }
  .content-bg{
    min-height:25vh!important;
  }
  .card-box-all{
    .card-box{
        .content-box{
          .title{
            padding:0.5vh 1vw!important;
          }
          .text{
            height:6vh !important;
            line-height: 1vh !important;
          }
        }
      }
  }
  .join-text-box{
    .content{
      height:31vh!important;
      .contentTitle{
        padding:0!important;
        margin: 0!important;
      }
      .text{
        width:65%!important;
        height:31vh!important;
        margin-left: 10vw!important;
        form{
          padding:10px!important;
          input{
            padding:0px!important;
            height: 2vh!important;
          }
          textarea{
            padding:0!important;
            height:4vh !important;
          }
          .btn-box{
            font-size:1vw;
            height:1.5vh;
            line-height: 1.5vh;
          }
        }
      }
      .right{
        margin-top: 17.8vh!important;
      }
    }
  }
  .map-message{
    height:2vh!important;
    line-height: 2vh!important;
  }
  .map{
    height:10vh!important;
    background-size:100% 100% !important;
    .animated-image{
      height:10px !important;
      width:10px!important;
      top:3vh!important;
    }
  }
  .us-bg{
    margin-top: -18vh!important;
  }
  @keyframes slide {
    0% {
      transform: translateY(0)!important;
    }
    50% {
      transform: translateY(1vh) !important;
    }
    100% {
      transform: translateY(0)!important;
    }
  }
}
</style>