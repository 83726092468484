<template>
  <div class="stepsBox_all">
    <div class="stepsTitle">探索企业数字化解决方案和产业互联网新商业模式</div>
    <div class="card-all">
      <div v-for="(count, index) in items" :key="index" class="card-box">
        <img :src="require('@/assets/' + count.imagePath)" alt="">
          <div class="text-box">
            <span class="title">{{ count.title_cn }}</span>
            <span class="content">{{ count.text }}</span>
          </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "FourSteps",
  data() {
    return {
      items: [
        {
          imagePath: 'Consult-icon.png',
          title_cn: '咨询',
          title_en: 'Consult',
          text: '拥有6位IBM BLM战略体系实践经验的咨询专家。',
        },
        {
          imagePath: 'Product-icon.png',
          title_cn: '产品',
          title_en: 'Product',
          text: '独创的1+3产品体系。',
        },
        {
          imagePath: 'Operate-icon.png',
          title_cn: '运营',
          title_en: 'Operate',
          text: '创新传统业务模式。'
        },
        {
          imagePath: 'Technology-icon.png',
          title_cn: '技术',
          title_en: 'Technology',
          text: '跨平台集成和低代码交付降低企业成本。'
        }
      ],
    }
  }
}
</script>

<style scoped lang="less">
.stepsBox_all{
  width:100%;
  margin-top: -47vh;
  position: absolute;
  background: url("@/assets/steps-bg2.png")no-repeat scroll 50% 100%;
  background-size: 60% 80%;
  float: left;
  .stepsTitle{
    text-align: center;
    color:#fff;
    font-size:1.5vw;
  }
}
.card-all{
  display: flex;
  justify-content: center;
  width:80%;
  margin: 0 auto;
}
.card-box{
  width:calc(100% / 4 - 40px);
  color:#fff;
  float: left;
  img{
    width:24%;
    height:10vh;
    display: flex;
    justify-content:center;
    margin: 0 auto;
  }
  .text-box{
    width:100%;
    display: block;
    float: left;
    font-size: 0.8vw;
    line-height: 3vh;
    .title{
      width:100%;
      display: flex;
      justify-content: center;
      text-align: center;
      font-size:1.5vw;
      font-family:'textFontFZ',serif;
    }
    .content{
      width:55%;
      display: flex;
      justify-content: center;
      margin:10px auto;
      padding-left:20px;
      line-height: 3vh;
    }
  }
}
</style>