<template>
  <div class="join-box">
    <div class="banner-box">
      <img src="@/assets/contact_us.png" alt="">
    </div>
  </div>
  <div class="join-bg">
    <div class="join-text-box">
      <div class="content">
        <div class="contentTitle">
          <span class="cases-cn">在线联系</span>
          <span class="cases-en">Online Contact</span>
        </div>
        <div class="text">
          <from-windows></from-windows>

        </div>
        <img src="@/assets/companyphoto-woman2.png" alt="" class="right">
      </div>
    </div>
  </div>
  <div class="map-all">
<!--    <div class="companyTitle">-->
<!--      <span class="cases-cn">关于我们</span>-->
<!--      <span class="cases-en">About Us</span>-->
<!--    </div>-->
    <div class="map-message">
      <p>
        <span>公司地址：<span>{{add}}</span></span>
      </p>
    </div>
    <div class="map">
      <img class="animated-image" src="@/assets/coordinate.png" alt="Animated Image">
    </div>
  </div>
</template>

<script>
import fromWindows from "@/components/FromWindows";
export default {
  name: "ContactUs",
  components:{
    fromWindows
  },
  data() {
    return {
      add:'上海市杨浦区五角场街道安波路998号创智汇2号楼322室',
    };
  },
  methods: {
    submitForm() {
      console.log('Form data:', this.formData);
      this.$emit('submit-success', this.formData);
      // 处理表单提交逻辑
    }
  }
};
</script>

<style scoped lang="less">
.join-box{
  width:100%;
  float: left;
  .banner-box{
    width:100%;
    display: flex;
    justify-content: center;
    img{
      width:100%;
    }
  }
}
.join-bg{
  width:100%;
  background: #ffffff;
  float: left;
}
.join-text-box{
  width:100%;
  float: left;
  .left,.right{
    display: block;
    float: left;
  }
  .left{
    width:20vw;
  }
  .right{
    margin-top: 23.8vh;
    width:24vw;
  }
  .content{
    background: url("@/assets/join-us.png") scroll 50% 50%;
    width:100%;
    height:80vh;
    display: block;
    text-indent: 2em;
    float: left;
    .text{
      width:73%;
      height:80vh;
      float: left;
    }
  }
}
.title-text{
  text-align: center;
  color:#485873;
  font-size: 2vw;
}

.contentTitle{
  color:#485873;
  font-family: 'textFontW7' , serif;
  font-style: italic;
  padding:20px 0;
  margin-left: 12vw;
  .cases-cn{
    padding-left:2vw;
    font-size: 1.8vw;
  }
  .cases-en{
    padding-left: 20px;
    font-size: 1.5vw;
  }
}
.btn-box-all{
  width:100%;
  height:7vh;
}
.map-all{
  margin: 20px auto;
  width:77%;
  height:30vh;
  .companyTitle{
    color:#485873;
    font-family: 'textFontW7' , serif;
    font-style: italic;
    .cases-cn{
      padding-left:2vw;
      font-size: 1.8vw;
    }
    .cases-en{
      padding-left: 20px;
      font-size: 1.5vw;
    }
  }
}
.map{
  width:100%;
  height:30vh;
  margin-bottom: 20px;
  background: url("@/assets/map.png") no-repeat scroll 50% 50%;
  float: left;
  .animated-image {
    width: 40px;
    height:40px;
    animation: slide 2s infinite;
    position: relative;
    top:11vh;
    left:35vw;
  }

  @keyframes slide {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-1vh);
    }
    100% {
      transform: translateY(0);
    }
  }
}
.map-message{
  background: rgba(255, 255, 255, 0.6);
  height:6vh;
  line-height: 5vh;
  width:100%;
  margin:2vh auto;
  float: left;
  border-radius: 10px;

  p{
    padding-left:2vw;
    font-size: 2vw;
    span{
      font-size: 1.2vw;
    }
  }
}

@media screen and (max-width:1920px) and (min-width:1600px){
  .join-text-box{
    .right{
      margin-top: 28vh;
      width:25vw;
    }
  }
  .map {
    .animated-image {
      top: 10vh;
      left: 33vw;
    }
  }
}
@media screen and (max-width:1600px) and (min-width:1440px){
  .join-text-box{
    .right{
      margin-top:30vh;
      width:25vw;
    }
  }
  .map {
    .animated-image {
      top: 10vh;
      left: 32vw;
    }
  }
}
@media screen and (max-width:1440px) and (min-width:1366px){
  .join-text-box{
    .right{
      margin-top:35vh;
      width:25vw;
    }
  }
  .map {
    .animated-image {
      top: 10vh;
      left: 32vw;
    }
  }
}
@media screen and (max-width:1366px) and (min-width:1280px){
  .join-text-box{
    .right{
      margin-top:38vh;
      width:25vw;
    }
  }
  .map {
    .animated-image {
      top: 10vh;
      left: 32vw;
    }
  }
}
@media screen and (max-width:1280px) and (min-width:1024px){
  .join-text-box{
    .right{
      margin-top:38vh;
      width:25vw;
    }
  }
  .map {
    .animated-image {
      top: 10vh;
      left: 32vw;
    }
  }
}
@media screen and (max-width:1024px) and (min-width:500px){
  .join-text-box{
    .right{
      margin-top:38vh;
      width:25vw;
    }
  }
  .map {
    .animated-image {
      top: 10vh;
      left: 27vw;
    }
  }
}
</style>