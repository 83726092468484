<template>
<div class="box-all">
  <IndexTop></IndexTop>
  <index-footer></index-footer>
  <div >
    <button @click="scrollToTop" v-show="showScrollToTopButton" class="btn-up-hand">
      <img src="@/assets/rocket.png" alt="">
    </button>
  </div>
</div>
</template>

<script>
import IndexTop from '@/components/IndexTop.vue'
import IndexFooter from '@/components/IndexFooter.vue'

export default {
  name: 'App',
  components: {
    IndexTop,IndexFooter
  },
  data() {
    return {
      showScrollToTopButton: false,
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
  },
  mounted() {
    // 监听滚动事件来控制按钮的显示和隐藏
    window.addEventListener('scroll', () => {
      this.showScrollToTopButton = window.scrollY > 500;
    });
  },
  unmounted() {
    // 清理监听器
    window.removeEventListener('scroll', () => {});
  },
};
</script>

<style scoped lang="less">
.box-all{
  width:100%;
  .btn-up-hand{
    background: none;
    position: fixed;
    bottom: 20px;
    right: 20px;
    img{
      opacity: 0.8;
      display: block;
      width:30px;
      height:30px;
      background: #ffffff;
      padding:5px;
      border:2px solid #fff;
      border-radius: 50%;
      box-shadow:inset 0 0 5px #000;
    }
  }
}
.scaled-container {
  transform: scale(var(--scaleFactor));
  transform-origin: top left;
  height: 100vh; /* 设置容器高度为浏览器可视空间的高度 */
  width: 100vw; /* 设置容器宽度为浏览器可视空间的宽度 */
}
</style>