<template>
  <div class="top-all">
<!--  <div class="top-bg">-->
<!--    <div class="top-box">-->
<!--      <div class="left">-->
<!--        <img src="@/assets/coordinate.png" alt="">-->
<!--        <span>上海</span>-->
<!--        <a href="">[切换]</a>-->
<!--      </div>-->
<!--      <div class="right">-->
<!--        探索企业数字化解决方案和产业互联网新商业模式！-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
  <div class="top-menu">
    <div class="logo">
      <img src="@/assets/logo.png" alt="">
    </div>
    <div class="menu">
      <div class="top"></div>
      <div class="down">
        <router-view></router-view>
        <ul>
          <li @click="activeTab = 'home'" :class="{ active: activeTab === 'home' }"><router-link to="/" class="link-span">首页</router-link></li>
          <li @click="activeTab = 'Company_Profile'" :class="{ active: activeTab === 'Company_Profile' }"><span>公司简介</span></li>
          <li @click="activeTab = 'Success_Cases'" :class="{ active: activeTab === 'Success_Cases' }"><span>成功案例</span></li>
<!--          <li @click="activeTab = 'Solution'" :class="{ active: activeTab === 'Solution' }"><span>解决方案</span></li>-->
          <li @click="activeTab = 'contact_us'" :class="{ active: activeTab === 'contact_us' }"><span>联系我们</span></li>
        </ul>
      </div>
    </div>
  </div>
    <div class="tabs_box">
      <div v-if="activeTab === 'home'" >
        <FocusImage></FocusImage>
        <div class="message-box-all">
          <AdvertisementBox></AdvertisementBox>
        </div>
      </div>
      <div v-else-if="activeTab === 'Company_Profile'">
        <AboutUs></AboutUs>
      </div>
      <div v-else-if="activeTab === 'Success_Cases'">
        <SuccessCases></SuccessCases>
      </div>
<!--      <div v-else-if="activeTab === 'Solution'">-->
<!--        <total-solution></total-solution>-->
<!--      </div>-->
      <div v-else-if="activeTab === 'contact_us'">
        <contact-us></contact-us>
      </div>
    </div>
  </div>
</template>

<script>
import FocusImage from '@/components/FocusImage.vue'
import AdvertisementBox from '@/components/AdvertisementBox.vue'
import AboutUs from '@/components/AboutUs.vue'
import ContactUs from "@/components/ContactUs.vue";
import SuccessCases from "@/components/SuccessCases.vue";
// import totalSolution from "@/components/TotalSolution.vue";

export default {
  name: "IndexTop",
  components: {
    FocusImage,AdvertisementBox,AboutUs,ContactUs,SuccessCases,
  },
  data() {
    return {
      activeTab: 'home' ,// 默认选中第一个标签
      items:[
          {
            text_en:'home',
            text_cn:'首页'
          },
        {
          text_en:'Company_Profile',
          text_cn:'公司简介'
        },
        {
          text_en:'Success_Cases',
          text_cn:'成功案例'
        },
        {
          text_en:'Solution',
          text_cn:'解决方案'
        },
        {
          text_en:'about',
          text_cn:'加入我们'
        },
      ]
    };
  },
}
</script>

<style scoped lang="less">
.top-all{
}
.top-bg{
  background: rgba(159,94,45,1);
  .top-box{
    color: #fff;
    width:80%;
    height:20px;
    margin: 0 auto;
    line-height: 20px;
    .left{
      width:200px;
      height:20px;
      float:left;
      img{
        display: block;
        width:20px;
        height:20px;
        background:url("@/assets/coordinate.png") no-repeat 0 0;
        float: left;
        margin-right:1px;
      }
      span{
        width:30px;
        display: block;
        float: left;
      }
      a{
        color:#fff;
        width:40px;
        display: block;
        float: left;
        text-decoration: none;
      }
    }
    .right{
      display: flex;
      float: right;
    }
  }
}
.top-menu{
  position: relative;
  z-index: 999;
  width:80%;
  margin: 20px auto 10px;
  .logo{
    width:10vw;
    height:9vh;
    float: left;
    //filter: drop-shadow(1px 1px 2px #333);
    margin:0 0 2vh 5vw;
    img{
      width:12vw;
    }
  }
  .menu{
    width:30vw;
    float: right;
    padding-right:5vw;
    .top{
      width:100%;
      height:6vh;
      float: right;
    }
    .down{
      display: flex;
      width:100%;
      justify-content: flex-end;
      ul{
        width:100%;
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: flex-end;
        .active{
          span,.link-span{
            font-weight: bold;
            color: #bd8656;
          }
        }
        li{
          font-family: 'textFontW7',serif;
          cursor: pointer;
          width:150px;
          float: right;
          text-align: center;
          span,.link-span{
            font-size: 1vw;
            width:100%;
            text-align: center;
            color: #d0d0d0;
            text-decoration: none;
          }
          span:hover,.link-span:hover{
            color: #bd8656;
          }
          span:active,.link-span:active{
            color: #d0d0d0;
          }
        }
      }
    }
  }
}
.tabs_box{
  width:100%;
  float: left;
  div{
    float: left;
  }
}
.message-box-all{
  width:100%;
  margin-top: -19vh;
}
@media screen and (max-width:2560px) and (max-height: 700px){

}
@media screen and (max-width:2560px) and (max-height: 600px){

}
@media screen and (max-width:2560px) and (max-height: 400px){

}
</style>