<template>
<footer class="footer-all">
  <div class="footer-text">
    <div class="footer-logo">
      <img src="@/assets/footer-logo.png" alt="">
    </div>
    <div class="footer-menu">
      <dl  v-for="(count, index) in items" :key="index" class="menu-text">
        <dt>{{count.title}}</dt>
        <dd><span>{{count.textOne}}</span></dd>
        <dd><span>{{count.textTwo}}</span></dd>
        <dd><span>{{count.textThree}}</span></dd>
        <dd><span>{{count.textFour}}</span></dd>
      </dl>
    </div>
    <div class="phone">
      <div class="phoneTitle">
        <span>联系我们</span>
      </div>
      <div class="phoneText">
        <span></span>
        <span class="phoneNum">{{tel}}</span>
      </div>
      <div class="phoneAdd">
        <span>公司地址：</span>
        <span class="addText">{{add}}</span>
      </div>
    </div>
  </div>
  <div class="end-lbl">
    <div class="msg">
      <span>© Copyright©2010-2024</span>
      <span style="padding-left:5px;">艺数范式(上海)家居科技有限公司</span>
      <i>|</i>
      <a href="https://beian.miit.gov.cn/">沪ICP备2023029485号</a>
    </div>
  </div>
</footer>
</template>

<script>
export default {
  name: "IndexFooter",
  data(){
    return{
      tel:"",
      add:'上海市杨浦区五角场街道安波路998号创智汇2号楼322室',
      items:[
          {
            title:'关于我们',
            textOne:'公司简介',
            textTwo:'企业文化',
            textThree:'人才招聘',
            textFour:'联系我们'
          },
          {
            title:'产品介绍',
            textOne:'产品简介',
            textTwo:'产品展示',
            textThree:'设计方案',
            textFour:'解决方案'
          },
          {
            title:'运营保障',
            textOne:'验收制度',
            textTwo:'售后服务',
            textThree:'管理制度',
            textFour:'解决方案'
          }
      ]
    }
  }
}
</script>

<style scoped lang="less">
.footer-all{
  width:100%;
  height:40vh;
  float:left ;
  background: url("@/assets/footer-bg.png")no-repeat scroll 50% 50%;
  background-size: 100% 100%;
  .footer-text{
    width:70%;
    margin: 0 auto;
    color:#fff;
    .footer-logo{
      width:100%;
      height:13vh;
      //background: url("@/assets/footer-logo.png")no-repeat scroll 0 0;
      //background-size: 20% 100%;
      img{
        display: block;
        margin-top: 2vh;
        width:12vw ;
      }
    }
  }
  .footer-menu{
    width:50%;

    .menu-text{
      width:calc(100% / 3 - 15px);
      height:20vh;
      float: left;
      color:#cbc8c4;
      margin-left: 13px;
      dt{
        font-size: 1vw;
        font-weight: bold;
        margin-bottom: 5px;
      }
      dd{
        font-size: 0.8vw;
        line-height: 3vh;
        a{
          color:#cbc8c4;
          text-decoration: none;
        }
      }
    }
  }

  .phone{
    widht:30%;
    height:20vh;
    float: left;
    color:#cbc8c4;
    .phoneTitle{
      font-size: 1vw;
      font-weight: bold;
      margin-bottom: 5px;

    }
    .phoneText{
      font-size: 1vw;
      .phoneNum{
        font-size:2vw;
      }
    }
    .phoneAdd{
      margin-top: 5px;
      font-size: 1vw;
    }
  }
}
.end-lbl{
  color: rgba(255, 255, 255, 0.5);
  width:100%;
  margin: 20px auto 0;
  text-align: center;
  float: left;
  .msg{
    width:600px;
    margin: 0 auto;
  }
  span,a,i{
    display: block;
    float: left;
  }
  i{
    padding:0 5px;
  }
  a{
    color: rgba(255, 255, 255, 0.5);
    text-decoration: none;
  }
}
@media screen and (max-width:2560px){
  .footer-all{
    height:39vh;
  }
}
</style>