<template>
  <div class="advertisement-box">
  <div class="advertisement-title-box">
    <div class="title-box">
      <span class="up">最专业的咨询营销方案</span>
      <span class="bottom">为客户提供由传统方式向数字化转型一站式解决方案</span>
      <svg width="40" height="40" viewBox="0 0 50 40">
        <polygon points="25,0 55,35 0,30" fill="#f6f3ef"/>
      </svg>
    </div>
  </div>
  <div style="width:80%;margin: 0 auto">
    <div class="advertisement-one" v-for="(count, index) in items" :key="index">
      <div class="card-up">
        <div class="card-content">
          <div class="title-box">
            <span class="title-text">{{count.text1}}</span>
            <span class="subtitle-text">{{count.subtitle1}}</span>
          </div>
          <div class="text-box">
            <span>{{count.contentText1}}</span>
<!--            <router-link :to="count.linkPath1" class="btn-a">{{count.buttonText}}</router-link>-->
<!--            <a href="#">{{count.buttonText}}</a>-->
          </div>
        </div>
        <img :src="require('@/assets/' + count.imagePath1)" alt="">
      </div>
      <div class="decorative-strip-left-bottom"></div>
      <div class="card-down">
        <img :src="require('@/assets/' + count.imagePath2)" alt="">
        <div class="card-content">
          <div  class="title-box">
            <span class="title-text">{{count.text2}}</span>
            <span class="subtitle-text">{{count.subtitle2}}</span>
          </div>
          <div class="text-box">
            <span>{{count.contentText2}}</span>
<!--            <router-link :to="count.linkPath2" class="btn-a">{{count.buttonText}}</router-link>-->
<!--            <a href="#">{{count.buttonText}}</a>-->
          </div>
        </div>
      </div>
    </div>
    <div class="decorative-strip-right-bottom"></div>
  </div>
</div>
</template>

<script>

export default {
  name: "AdvertisementBox",
  components: {

  },
  data(){
    return{
      items:[
        {
          text1:'数字营销',
          subtitle1:'流量体系',
          contentText1:'数字营销小程序是基于私域流量领域的全民内容创造和客户服务工具；通过将名片、案例库、文章、活动、海报、视频等营销素材在线化和智能化，帮助实现营销人员的私域流量经营。',
          text2:'客户服务',
          subtitle2:'服务体系',
          contentText2:'提供一站式家装过程信息，从量房开始记录房屋在各阶段的节点进度、项目动态、费用明细、图纸档案、产品选材等。足不出户，了解新家正在发生的变化，对进度、质量、成本有把控。',
          imagePath1:'product-img01.png',
          imagePath2:'product-img02.png',
          buttonText:'了解详情',
          linkPath1:'/',
          linkPath2:'/About'
        },
        {
          text1:'产品库',
          subtitle1:'收益体系',
          contentText1:'从产品库到商城，为用户提供全面的产品运营功能，包含产品分类、产品属性、购物清单、活动管理、销售榜单、多模式选品等多维度。',
          text2:'项目管理中台',
          subtitle2:'交付保障体系',
          contentText2:'项目管理中台集成了项目规划、资源调配、进度监控与质量管理功能，实现了信息流的高效对接。提升了项目执行效率，保障了施工品质，为客户打造了无忧的家装体验，同时为企业带来了成本优化与管理升级的双重价值。',
          imagePath1:'product-img03.png',
          imagePath2:'product-img04.png',
          buttonText:'了解详情',
          linkPath1:'/',
          linkPath2:'/about'
        }
      ]
    }
  },
  methods: {

  }
}
</script>

<style scoped lang="less">
.advertisement-box{
  width:100%;
  .advertisement-title-box{
    width:100%;
    height:80px;
    padding-top:10px;
    background-image: linear-gradient(to bottom, #7e6245, #423424);
    span{
      display: flex;
      justify-content: center;
      color:#fff;
    }
    .up{
      height:40px;
      line-height: 50px;
      font-size: 1vw;
    }
    .bottom{
      height:20px;
      line-height: 20px;
      font-size: 0.8vw;
    }
    svg{
      display: block;
      margin: 0 auto;
    }

  }
  .advertisement-one{
    width:100%;
    margin-top: 4%;
    float: left;
    img{
      display: block;
      width:38%;
      float: left;
    }
  }
}
.card-up,.card-down{
  width:60%;
  position: relative;
  z-index: 999;
  .card-content{
    width:60%;
    float: left;
    .title-box{
      width:100%;
      .title-text{
        display: block;
        float:left;
        color:#d59f66;
        font-size: 2vw;
        font-family: 'textFontFZ',serif;
        text-align: right;
      }
      .subtitle-text{
        padding-left:10px;
        width:150px;
        display: block;
        float:left;
        line-height: 6vh;
        color:#485873;
        font-size: 0.9vw;
      }
    }
    .text-box{
      width:100%;
      line-height: 4vh;
      color:#485873;
      span{
        font-size: 0.8vw;
        width:27vw;
        display: block;
        float: left;
      }
    }
  }
  a,.btn-a{
    margin-top: 20px;
    display: block;
    float: left;
    width:96px;
    height:36px;
    line-height: 36px;
    border:1px solid #d8a268;
    background: #faf6f1;
    text-decoration: none;
    text-align: center;
    color:#d8a268;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
  }
}
.card-up{
  float: left;
  margin-top: 2%;
  margin-left: 20%;
}
.card-down{
  float: right;
  margin: 10% 20% 30px 0;
  img{
    margin-right: 2%;
  }
  a{
    float: right;
    margin-right: 20px;
  }
}
.decorative-strip-left-bottom , .decorative-strip-right-bottom{
  width:100%;
  height:30vh;
  position: relative;
  z-index: 2;
  opacity: 0.3;
}
.decorative-strip-left-bottom{
  background: url("@/assets/decorative-strip-left-bottom.png")no-repeat scroll 50% 40%;
  background-size: 65% 65%;
  top: 25vh;
}
.decorative-strip-right-bottom{
  background: url("@/assets/decorative-strip-right-bottom.png")no-repeat scroll 50% 40%;
  background-size: 65% 65%;
  top:70vh;
}
@media screen and (max-width:2000px){
  .decorative-strip-left-bottom{
    background-size: 65% 55%;
    top:24vh;
  }
  .decorative-strip-right-bottom{
    background-size: 65% 55%;
    top:95vh;
  }
}
@media screen and (max-width:1920px){
  .decorative-strip-left-bottom{
    background-size: 65% 55%;
    top:24vh;
  }
  .decorative-strip-right-bottom{
    background-size: 65% 55%;
    top:75vh;
  }
}
@media screen and (max-width:1800px){
  .decorative-strip-left-bottom{
    background-size: 65% 55%;
    top:24vh;
  }
  .decorative-strip-right-bottom{
    background-size: 65% 55%;
    top:85vh;
  }
}
@media screen and (max-width:1700px){
  .decorative-strip-left-bottom{
    background-size: 65% 55%;
    top:24vh;
  }
  .decorative-strip-right-bottom{
    background-size: 65% 55%;
    top:75vh;
  }
}
@media screen and (max-width:1600px){
  .decorative-strip-left-bottom{
    background-size: 65% 55%;
    top:24vh;
  }
  .decorative-strip-right-bottom{
    background-size: 65% 55%;
    top:72vh;
  }
}
@media screen and (max-width:1500px){
  .decorative-strip-left-bottom{
    background-size: 65% 55%;
    top:24vh;
  }
  .decorative-strip-right-bottom{
    background-size: 65% 55%;
    top:68vh;
  }
}
@media screen and (max-width:1440px){
  .decorative-strip-left-bottom{
    background-size: 65% 55%;
    top:24vh;
  }
  .decorative-strip-right-bottom{
    background-size: 65% 55%;
    top:69vh;
  }
}
@media screen and (max-width:1400px){
  .decorative-strip-left-bottom{
    background-size: 65% 55%;
    top:22vh;
  }
  .decorative-strip-right-bottom{
    background-size: 65% 55%;
    top:550px;
  }
}
@media screen and (max-width:1366px){
  .decorative-strip-left-bottom{
    background-size: 65% 55%;
    top:22vh;
  }
  .decorative-strip-right-bottom{
    background-size: 65% 55%;
    top:550px;
  }
}
@media screen and (max-width:1300px){
  .decorative-strip-left-bottom{
    background-size: 65% 55%;
    top:22vh;
  }
  .decorative-strip-right-bottom{
    background-size: 65% 50%;
    top:68vh;
  }
}
@media screen and (max-width:1280px){
  .decorative-strip-left-bottom{
    background-size: 65% 45%;
    top:21vh;
  }
  .decorative-strip-right-bottom{
    background-size: 65% 40%;
    top:67vh;
  }
}
@media screen and (max-width:1100px){
  .decorative-strip-left-bottom{
    background-size: 65% 40%;
    top:21vh;
  }
  .decorative-strip-right-bottom{
    background-size: 60% 30%;
    top:62vh;
  }
}
@media screen and (max-width:1024px){
  .decorative-strip-left-bottom{
    background-size: 65% 40%;
    top:25vh;
  }
  .decorative-strip-right-bottom{
    background-size: 65% 40%;
    top:70vh;
  }
}
</style>