<template>
<div class="bg" v-if="divVisible">
  <span>提交成功！</span>
</div>
</template>

<script>
export default {
  name: "ShowWindow",
  data() {
    return {
      divVisible: true // 控制div的显示状态
    };
  },
  methods: {

  }
}
</script>

<style scoped lang="less">
.bg{
  width: 400px;
  height: 100px;
  line-height: 100px;
  background: rgba(255,255,255,.8);
  position: absolute;
  top: 80%;
  left: 40%;
  text-align: center;
  border:4px solid rgba(255,255,255,1);
  border-radius: 10px;
  box-shadow: 0 0 5px #939393;
  span{
    display: block;
    width:100%;
    height:100px;
    font-size: 20px;
  }
  button{
    width:24px;
    height:24px;
    line-height: 24px;
    color: #ffffff;
    font-weight: bold;
    border-radius: 50%;
    background: #ff0000;
    position: relative;
    top: -150px;
    left:200px;
  }
}
</style>