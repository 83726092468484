<template>
  <form @submit.prevent="submitForm">
    <div class="name">
      <span>姓名:</span>
      <input v-model="formData.name" type="text" placeholder="请输入您的姓名">
    </div>
    <div class="Phone">
      <span>电话:</span>
      <input v-model="formData.phone"  type="tel" pattern="[0-9]*" name="mobile" maxlength="11" value="" placeholder="请输入您的手机号码" >
    </div>
    <div class="email">
      <span>邮箱:</span>
      <input v-model="formData.email" type="email" placeholder="请输入您的邮箱地址">
    </div>
    <div class="email">
      <span>留言板:</span>
      <textarea v-model="formData.text"  placeholder="您可以留言提出需要解答的相关问题，我们收到后会及时联系您"></textarea>
    </div>
    <div class="btn-box-all"></div>
    <button type="submit" class="btn-box" @click="showDiv">提交</button>
    <showWindow v-if="showError"></showWindow>
  </form>

</template>

<script>
import showWindow from "@/components/ShowWindow";
export default {
  name: "child-component",
  components:{
    showWindow
  },
  data() {
    return {
      formData: {
        name: '',
        phone:'',
        email: '',
        text:''
      },
      divVisible:false,
      showError: false
    };
  },
  methods: {
    submitForm() {
      this.$emit('submit-success', this.formData);
    },
    showDiv() {
      setTimeout(this.divVisible,1000)
    }
  }
}
</script>

<style scoped lang="less">
form{
  display: block;
  width:40vw;
  padding:4vh 5vw;
  margin: 0 auto;
  background: rgba(177, 177, 177, 0.3);
  border-radius: 20px;
  .name,.email,.Phone{
    color:#485873;
    font-family: '华康圆体W7(P)' , serif;
    margin: 0.5vw auto;
    span{
      font-size: 1.3vw;
    }
  }
}
input{
  width:100%;
  height:4vh;
  display: block;
  float: left;
  margin: 0.6vw auto;
  padding-left:30px;
  border-radius:4px;
  background: #fff1e6;
  border:1px solid #d8a268;
}
textarea{
  width:100%;
  height:15vh;
  display: block;
  float: left;
  margin: 20px auto;
  line-height: 20px;
  padding:10px 0 0 30px;
  border-radius:4px;
  background: #fff1e6;
  border:1px solid #d8a268;
}
.btn-box{
  margin: 20px auto 0;
  display: block;
  width:15vw;
  height:4vh;
  line-height: 4vh;
  background: #985600;
  text-decoration: none;
  text-align: center;
  color: #fff;
  border-radius: 4px;
  font-size: 0.8vw;
  cursor: pointer;
}
.btn-box:hover{
  background: #b97d43 ;
}
</style>