<template>
  <div>
    <h1>About Us</h1>
    <p>This is the about page content.</p>
  </div>
</template>

<script>
export default {
  name: "AboutPage"
}
</script>

<style scoped>

</style>