<template>
  <router-view/>
</template>

<script>

export default {
  name: 'App',

};
</script>

<style lang="less">
body{
  background: #f6f3ef;
  overflow-x:hidden;
}
body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,form,input,table,tbody,p,th,td,em{
  -webkit-text-size-adjust:none;
  margin:0;
  padding:0;
  border:none;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
  font-size:14px;
  font-family:"Microsoft Yahei","微软雅黑",Tahoma,Arial,Helvetica,STHeiti,serif;
}
html,body{
  height:100%;
}
img,input,button,a,select,textarea{
  margin:0;
  padding:0;
  resize:none;
  border:none;
  outline:none;
}
ol,ul{
  list-style:none;
}
h1,h2,h3,h4,h5,h6,p,em,i,b,em{
  font-size:100%;
  word-wrap:break-word;
  font-weight:normal;
  font-style:normal;}
a:active, a:focus{
  outline:none;
}
button::-moz-focus-inner,input::-moz-focus-inner{
  padding:0;
  border:0;
}

@font-face {
  font-family: 'imgFontPM';
  src: url('~@/font/PMZD.ttf') format('truetype');
}
@font-face {
  font-family: 'textFontFZ';
  src: url('~@/font/FZCHSJW.TTF') format('truetype');

}
@font-face {
  font-family: 'textFontW3';
  src: url('~@/font/HKHTW3.TTF') format('truetype');
}

@font-face {
  font-family: 'textFontW7';
  src: url('~@/font/HKHTW7.TTF') format('truetype');
}
//chrome滚动条样式
// 滚动条整体宽度
::-webkit-scrollbar {
  width: 8px;
  height:100vh;
}
// 滚动条交接区底色
::-webkit-scrollbar-corner {
  background:  rgba(0,0,0,0.3);
}
// 滚动条滑槽样式
::-webkit-scrollbar-track {
  width: 6px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
// 滚动条样式
::-webkit-scrollbar-thumb {
  background: #333333;
  -webkit-box-shadow: inset 0 0 6px #c4c4c4;
}
::-webkit-scrollbar-thumb:hover{
  background: #bbbbbb;
}
::-webkit-scrollbar-thumb:active{
  background: #c4c4c4;
}
// 浏览器失焦的样式
::-webkit-scrollbar-thumb:window-inactive {
  background: #333333;
}
.ant-dropdown ul.ant-dropdown-menu{
  // 滚动条滑槽样式
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,1);
    border-radius: 6px;
    background: #b1b1b1;
  }
}
//end
/**html{*/
/*  background-image:url(about:blank);*/
/*  background-attachment:fixed;*/
/*} !* 修正IE6振动bug *!*/


</style>
