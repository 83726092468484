<template>
  <div class="carousel">
    <div class="slides">
      <div v-for="(slide, index) in slides" :key="index" :class="{ active: index === currentIndex }"
           @mouseover="stopCarousel"
           @mouseleave="startCarousel">
        <div :class="slide.items">
          <span class="spanOne">{{slide.textOne}}</span>
          <span class="spanTwo">{{slide.textTwo}}</span>
        </div>
        <transition name="slide" mode="out-in" >
          <img :src="require('@/assets/'+ slide.imagePath)" alt="slide image" class="carousel-image">
<!--          <img src="@/assets/page1.png" alt="slide image">-->
        </transition>
      </div>
    </div>
    <p @click="prevSlide" class="prev">
      <img src="@/assets/l-btn.png" alt="">
    </p>
    <p @click="nextSlide" class="next">
      <img src="@/assets/r-btn.png" alt="">
    </p>
  </div>
  <div class="item">
      <span v-for="(image, index) in slides" :key="index" :class="{ active: currentIndex === index }" @click="currentIndex = index" class="cur"></span>
  </div>
  <four-steps></four-steps>
</template>

<script>
import FourSteps from '@/components/FourSteps.vue'

export default {
  name: "FocusImage",
  components: {
    FourSteps
  },
  data() {
    return {
      slides: [
        {
          textOne:'有为 为成交而生',
          textTwo:'一个人的工作室',
          imagePath: 'page3.png',
          items:'item3'
        },
        {
          textOne:'智慧赋能 行业领先',
          textTwo:'让AI助您的行业实现创新发展',
          imagePath: 'page1.png',
          items:'item1'
        },
        {
          textOne:'智能引领 行业变革',
          textTwo:'让AI助您的行业实现飞跃',
          imagePath: 'page2.png',
          items:'item2'
        }
      ],
      currentIndex: 0,
      timer: null
    };
  },
  created() {
    this.startCarousel();
  },
  computed: {
    classes() {
      return this.items.map((item, index) => `custom-class-${index + 1}`);
    }
  },
  methods: {
    startCarousel() {
      this.timer = setInterval(() => {
        this.nextSlide();
      }, 8000); // 切换间隔时间为5秒
    },
    stopCarousel() {
      clearInterval(this.timer);
    },
    prevSlide() {
      this.currentIndex = (this.currentIndex - 1 + this.slides.length) % this.slides.length;
    },
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.slides.length;
    }
  },
  beforeUnmount() {
    this.stopCarousel();
  }
};
</script>

<style scoped lang="less">
.carousel {
  position: relative;
  top: -18vh;
}

.slides {
  background: #333333;
}

.slides .active img{
  width:100%;
  display: block;
  transition: opacity 2s;
}

.slides img {
  max-width: 100%;
  display: none;
}
.slides .item1,.slides .item2,.slides .item3{
  display: none;
}
.slides .active{
  .item1,.item2,.item3{
    display: block;
    width:100%;
    text-align: center;
    color:#fff;
    position: absolute;
    .spanOne{
      width:100%;
      position: relative;
      font-size: 4vw;
      font-family: 'imgFontPM',serif;
      text-shadow: 2px 2px 4px #333;
    }
    .spanTwo{
      width:100%;
      position: relative;
      font-size: 1.5vw;
      font-family: 'textFontW3',serif;
      text-shadow: 1px 1px 3px #333;
    }
  }
  .item1{
    .spanOne{
      top:24vh;
      left:30vw;
    }
    .spanTwo{
      top:30vh;
      left:4vw;
    }
  }
  .item2{
    .spanOne{
      text-align: center;
      top:26vh;
      left:9vw;
    }
    .spanTwo{
      text-align: center;
      top:32vh;
      left:-16vw;
    }
  }
  .item3{
    .spanOne{
      top:42vh;
      left:26vw;
    }
    .spanTwo{
      top:34vh;
      left:-2vw;
    }
  }
}
p {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
  width:45px;
  height:44px;
  opacity:0.6;
  -moz-opacity:0.6;
  filter:alpha(opacity=60);
}
.prev .next{
  cursor:pointer;
  opacity:0.6;
  -moz-opacity:0.6;
  filter:alpha(opacity=60);
}
.prev:hover,.next:hover{
  opacity:1;
  -moz-opacity:1;
  filter:alpha(opacity=100);
}
.prev {
  left: 2vw;
}

.next {
  right: 2vw;
}
.item{
  display:flex;
  justify-content: flex-start;
  width:100px;
  height:5px;
  margin: 0 auto;
  z-index:9999;
  top:-50vh;
  left:0;
  text-align:center;
  position: relative;

  span:hover,.active{
    opacity:1;
    -moz-opacity:1;
    filter:alpha(opacity=1);
  }
  span{
      display:inline-block;
      width:24px;
      height:5px;
      margin-right:11px;
      background:#333;
      opacity:0.6;
      -moz-opacity:0.6;
      filter:alpha(opacity=60);
      overflow:hidden;
      cursor: pointer;
    }
  span.cur{
      background:#fff;
    }
}
.slide-enter-active, .slide-leave-active {
  transition: transform 0.5s;
}

.slide-enter, .slide-leave-to {
  transform: translateX(100%);
}

.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width:2560px) and (max-height: 700px){
  .slides .active {
    .item3 {
      .spanOne {
        top: 50vh;
        left: 20vw;
      }

      .spanTwo {
        top: 40vh;
        left: -8vw;
      }
    }
  }
}
@media screen and (max-width:2560px) and (max-height: 600px){
  .slides .active {
    .item1 {
      .spanOne {
        top: 50vh;
        left: 32vw;
      }

      .spanTwo {
        top: 58vh;
        left: 6vw;
      }
    }
    .item2 {
      .spanOne {
        top: 40vh;
        left: 10vw;
      }

      .spanTwo {
        top: 48vh;
        left: -14vw;
      }
    }
    .item3 {
      .spanOne {
        top: 50vh;
        left: 20vw;
      }

      .spanTwo {
        top: 36vh;
        left: -8vw;
      }
    }
  }
}
@media screen and (max-width:2560px) and (max-height: 400px){
  .slides .active {
    .item3 {
      .spanOne {
        top: 70vh;
        left: 20vw;
      }

      .spanTwo {
        top: 50vh;
        left: -8vw;
      }
    }
  }
}
</style>
